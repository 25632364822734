import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import {Container} from 'react-bootstrap';
import HeroFeatures from "../../components/heroes/hero-features"
import {injectIntl} from "gatsby-plugin-react-intl"
import Features from "../../components/features/features";
import FeaturesData from "../../content/features/index.json";
import PlatformData from "../../content/features/platform.json";
import MultiCardBlock from "../../components/misc/multi-card-block";

const FeaturesIndexPage = ({ intl, location }) => (
  <Layout>
    <Seo
        lang={intl.locale}
        title={FeaturesData[intl.locale].title}
        description={FeaturesData[intl.locale].text}
        pathname={location.pathname}
    />
    <HeroFeatures
        theme={`primary`}
        content={FeaturesData[intl.locale]}
    />
    <Features features={FeaturesData[intl.locale].features} />
    <Container>
        <MultiCardBlock content={{intro: PlatformData[intl.locale], cards: PlatformData[intl.locale].platform}} />
    </Container>
  </Layout>
)

export default injectIntl(FeaturesIndexPage)
