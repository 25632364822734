import React from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import {injectIntl} from "gatsby-plugin-react-intl";
import SimpleCard from "./simple-card";

function MultiCardBlock({ intl, content }) {

    return(
        <div className={`multi-card-block`}>
            {content.intro &&
                <Row>
                    <Container>
                        <div className={`intro`}>
                            <div className={`title`}>{content.intro.title}</div>
                            <div className={`text`} dangerouslySetInnerHTML={{__html: content.intro.text}}></div>
                        </div>
                    </Container>
                </Row>
            }
            <Row>
                {content.cards.map((card, i) => {
                    return(
                        <Col lg key={i}>
                            <SimpleCard content={card} />
                        </Col>
                    )
                })}
            </Row>
        </div>

    )

}

export default injectIntl(MultiCardBlock)

